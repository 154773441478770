import React from 'react';
import {Link, PageProps} from 'gatsby';
import Layout from '@/components/Layout';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

const About: React.FC<PageProps> = () => (
   <Layout pageTitle="Engaging Content">
      <section className="feature-hero">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-md-5 col-lg-4 margin-top-l">
                  <h1>Engaging Content</h1>
                  <p className="subtitle">Our platform empowers instructors to create the course that they envision.</p>
                  <p className="description">Lingco's content creation tools give instructors the ability to immerse their
                  students in the target language with the use of authentic content, enriched by engaging question
                        types.</p>
               </div>
               <div className="col-xs-12 col-md-5 col-lg-5 col-lg-offset-1">
                  <div className="feature-img wide"><img src="/img/feature-screen-content.png" /></div>
               </div>
            </div>
         </div>
      </section>
      <section className="platform-features">
         <div className="container-fluid">
            <div className="row center-xs middle-xs margin-bottom-xl">
               <div className="col-xs-12 col-sm-10 col-md-5 col-lg-4">
                  <h3>Build Interactive Activities</h3>
                  <p className="text-larger">Our activity builder makes it easy to create rich and interactive learning
                  experiences. Instructors can create questions that use audio, video, drag and drop, and even
                  speech recognition without the need for any plugins or additional software. Learn more about the
                        different types of questions available <OutboundLink href="https://help.lingco.io/en/articles/2751533-activity-item-types">here</OutboundLink>.
                  </p>
               </div>
               <div className="col-xs-12 col-sm-10 col-md-5 col-lg-3 col-lg-offset-1">
                  <div className="feature-img"><img src="/img/feature-crop-list.png" /></div>
               </div>
            </div>
            <div className="row center-xs middle-xs">
               <div className="col-xs-12 col-sm-10 col-lg-5 last-xs first-lg">
                  <div className="feature-img wide"><img src="/img/feature-crop-content.png" /></div>
               </div>
               <div className="col-xs-12 col-sm-10 col-lg-3 col-lg-offset-1">
                  <h3>Give Rich Feedback</h3>
                  <p className="text-larger">While most student responses can be automatically graded, we have built an
                  efficient grading workflow to enable instructors to quickly turn around work. With our extensive
                  suite of integrated review tools, instructors can easily provide inline feedback to both written
                        and audio responses.</p>
               </div>
               <div className="col-xs-12 col-lg-1"></div>
            </div>
         </div>
      </section>
      <section className="feature-column">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-10">
                  <div className="row center-xs">
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/content-view-module-1.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Extensive Global Library</h4>
                              <p>In addition to creating your own content, our global content library has hundreds
                              of activities and vocabulary sets created by our Subject Matter experts. Global
                                        content can be used, cloned, and modified to fit your course.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/transfer-user-group.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Share and Collaborate</h4>
                              <p>Content can be shared between instructors, departments and institutions, making
                              collaboration on curriculum and content development easy. Additionally, each
                              institution has a private library that instructors can publish content to, for
                                        use in future courses.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/data-download-8.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Easily Import</h4>
                              <p>Existing content from other platforms such as Quizlet and Learning Management
                              Systems like Canvas, D2L, Moodle, and Blackboard can be easily migrated to our
                                        platform without any additional work.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="section-divider"></div>
      </section>
      <section className="feature-more">
         <div className="container-fluid">
            <div className="row center-xs text-center margin-bottom-xl">
               <div className="col-xs-12 col-sm-10">
                  <h5 className="grey">Want to see more features? Check these out</h5>
               </div>
            </div>
            <div className="row center-xs">
               <div className="col-xs-12 col-md-5 margin-bottom-m-md"><Link to="/platform/personalized-learning">
                  <div className="card">
                     <div className="row middle-xs">
                        <div className="col-xs-12 col-md-6 margin-top-l">
                           <h4>Personalized Learning</h4>
                           <p>Every student is unique. It’s time that we take a personal approach.</p>
                        </div>
                        <div className="feature-more-img section"><img src="/img/feature-more-section.svg" /></div>
                     </div>
                  </div>
               </Link></div>
               <div className="col-xs-12 col-md-5"><Link to="/platform/insightful-analytics">
                  <div className="card">
                     <div className="row middle-xs">
                        <div className="col-xs-12 col-md-6 margin-top-l">
                           <h4>Insightful Analytics</h4>
                           <p>Our real-time analytics help you stay on top of your student’s progress.</p>
                        </div>
                        <div className="feature-more-img analytic"><img src="/img/feature-more-analytic.svg" /></div>
                     </div>
                  </div>
               </Link></div>
            </div>
         </div>
      </section>
   </Layout>
);

export default About;
